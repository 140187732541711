<template>
    <el-container>
        <!-- 背景图 -->
        <el-image :src="require('@/assets/Login/bg2.jpg')" class="login-banimg"></el-image>
        <!-- 顶部 -->
        <el-header>
            <Header />
        </el-header>
        <!-- 中间内容 -->
        <el-main>
            <div class="BigDiv">
                <div v-for="(item,index) in routeName" :key='index'>
                    <div class="KuaiDiv" :style="'background: linear-gradient(to right top,'+item.color+')'" @click="indexJump(item.component)">
                        <div :class="'iconfont '+item.iconfont"></div>
                        <span>{{item.meta.title}}</span>
                    </div>
                </div>
            </div>
        </el-main>
    </el-container>
</template>

<script>
import Header from "@/components/Header";
export default {
    data() {
        return {
            // 医院用户还是科室用户
            isDoctore: "",
            one: true, //医院基础数据
            two: true, //科室基础数据
            three: true, //科室标化工作量
            four: true, //科室难度系数监测
            five: true, //绩效测算与模拟
            six: true, //驱动型绩效智慧大数据
            // 0 医院用户   1 科室用户
            usertype: window.sessionStorage.getItem("type"),
            routeName: [],
        };
    },
    created() {
        this.winty();
        this.getRouters();
    },
    components: {
        Header,
    },
    //监听函数
    watch: {
        $route() {
            this.getRouters();
            // console.log(this.$store.state);
        },
    },
    methods: {
        async getRouters() {
            let res = await this.$axios.getRouters();
            this.$store.commit("saveRoute", JSON.stringify(res.data.data));
            this.routeName = JSON.parse(
                window.localStorage.getItem("routeName")
            );
        },
        indexJump(path) {
            // console.log(path);
            this.$router.push({ path: path });
        },
        winty() {
            if (window.sessionStorage.getItem("type") == 0) {
                this.administrators = "医院";
                this.isDoctore = true;
            } else {
                this.administrators = "科室";
                this.isDoctore = false;
            }
        },
        //   点击科室基础数据
        department() {
            if (this.isDoctore) {
                this.$router.push({ path: "/depIndex" });
            } else {
                this.$router.push({ path: "/MedireInfo" });
            }
        },
        // 绩效指标管理
        WorkIndMan() {
            this.$router.push({ path: "/WorkIndMan" });
        },
        basindic() {
            this.$router.push({ path: "/basindic" });
        },
        depperindicman() {
            this.$router.push({ path: "/depperindicman" });
        },
        // 绩效指标填报
        workIndManFill() {
            this.$router.push({ path: "/workIndManFill" });
        },
        // 点击科室标化工作量
        medicalService() {
            if (this.isDoctore) {
                this.$router.push({ path: "/totalstand" });
            } else {
                this.$router.push({ path: "/totalstandNoHo" });
            }
        },
        // 点击医院基础数据
        hospital() {
            if (this.isDoctore) {
                this.$router.push({ path: "/hospital" });
                if (!this.one) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 点击科室难度系数监测
        measurement() {
            if (this.isDoctore) {
                this.$router.push({ path: "/workMonit" });
                if (!this.four) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 绩效综合管理
        coperman() {
            if (this.isDoctore) {
                this.$router.push({ path: "/copeplan" });
                if (!this.five) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 机构信息管理
        orinman() {
            if (this.isDoctore) {
                this.$router.push({ path: "/oridepman" });
                if (!this.five) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        //  点击绩效测算与模拟
        income() {
            if (this.isDoctore) {
                this.$router.push({ path: "/depsimulation" });
                if (!this.five) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 点击结构调整
        structadju() {
            if (this.isDoctore) {
                this.$router.push({ path: "/structadju" });
                if (!this.five) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 点击驱动型绩效智慧大数据
        driven() {
            if (this.isDoctore) {
                this.$router.push({ path: "/driven" });
                if (!this.six) {
                    this.$message({
                        message: "没有访问权限",
                        type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "没有访问权限",
                    type: "warning",
                });
            }
        },
        // 开发中
        underDev() {
            this.$message({
                message: "正在开发中~",
                type: "warning",
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-header {
    position: relative;
    width: 100%;
    height: 80px !important;
    display: flex;
    align-items: center;
    background: rgb(2, 24, 36);
    color: white;
    justify-content: space-between;
}

.login-banimg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.el-main {
    position: relative;
    top: 50px;
    .BigDivDep {
        width: 70%;
    }
    .BigDiv {
        width: 88%;
    }
    .BigDiv,
    .BigDivDep {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 10px;
        justify-content: center;
        .KuaiDepDiv {
            width: 250px;
            height: 250px;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .iconfont {
                font-size: 40px;
                color: white;
            }
            span {
                color: white;
                font-size: 21px;
                margin-top: 10px;
            }
        }
        .KuaiDiv {
            width: 225px;
            height: 250px;
            margin-right: 10px;
            margin-bottom: 20px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .iconfont {
                font-size: 40px;
                color: white;
            }
            span {
                color: white;
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }
}
</style>
